




























import { Component, Vue, Prop } from 'vue-property-decorator';
import { ChevronUpIcon, ChevronDownIcon } from 'vue-feather-icons';
import Button from '@/components/ui/Button.vue';

@Component({
  components: {
    Button,
    ChevronDownIcon,
    ChevronUpIcon
  }
})
export default class NavigationDropdown extends Vue {
  @Prop({ type: String, required: true }) readonly lat?: string;
  @Prop({ type: String, required: true }) readonly lng?: string;
  @Prop({ type: String, required: true }) readonly label?: string;
  @Prop({ type: Boolean, required: false }) readonly disabled?: boolean;

  public open = false;

  public get disabledButton() {
    if (!this.lat || !this.lng) {
      return false;
    }

    return this.disabled ?? true;
  }

  public get mapsToNavigate() {
    return [
      {
        link: `https://mapy.cz/zakladni?q=${this.lat},${this.lng}&route=null,${this.lat},${this.lng}`,
        icon: 'https://d1qyg3ezd7xlmk.cloudfront.net/web-images/mapycz_logo.webp',
        name: 'Mapy.cz'
      },
      {
        link: `https://www.google.com/maps/dir/?api=1&destination=${this.lat},${this.lng}`,
        icon: 'https://d1qyg3ezd7xlmk.cloudfront.net/web-images/googlemaps_logo.webp',
        name: 'Google maps'
      },
      {
        link: `https://maps.apple.com/?daddr=${this.lat},${this.lng}`,
        icon: 'https://d1qyg3ezd7xlmk.cloudfront.net/web-images/applemaps_logo.webp',
        name: 'Mapy Apple'
      },
      {
        link: `https://www.waze.com/ul?ll=${this.lat},${this.lng}&navigate=yes`,
        icon: 'https://d1qyg3ezd7xlmk.cloudfront.net/web-images/waze_logo.webp',
        name: 'Waze'
      }
    ];
  }

  private handleMouseClick(ev: MouseEvent) {
    if (this.open && !this.$el.contains(ev.target as Node | null)) {
      this.open = false;
    }
  }

  private initMouseClickHandler() {
    document.removeEventListener('click', this.handleMouseClick);
    document.addEventListener('click', this.handleMouseClick);
  }

  private destroyMouseClickHandler() {
    this.open = false;
    document.removeEventListener('click', this.handleMouseClick);
  }

  mounted() {
    this.initMouseClickHandler();
  }

  activated() {
    this.initMouseClickHandler();
  }

  destroyed() {
    this.destroyMouseClickHandler();
  }

  deactivated() {
    this.destroyMouseClickHandler();
  }
}
