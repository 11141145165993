



































import { Component, Vue } from 'vue-property-decorator';
import carService from '../services/cars.service';
import { getReservationForMap, ReservationForMap } from '@/services/reservations.service';
import Button from '@/components/ui/Button.vue';
import { MapServisRoute } from '@/router/routes';
import ClientOnly from 'vue-client-only';
import * as locationParser from '@/components/map/locationParser';
import { Car } from '@/store/types';
import NavigationDropdown from '@/components/map/navigationDropdown.vue';
import { GeoJsonForMap, LatLngForMap } from '@/components/map/locationParser';
import moment from 'moment';

enum ReservationStatus {
  Before,
  Ongoing,
  After
}

@Component({
  components: {
    ClientOnly,
    MapBox: () => (process.client ? import('@/components/map/mapBox.vue') : import('vue-client-only')),
    Button,
    NavigationDropdown
  }
})
export default class Map extends Vue {
  public carsPositions: LatLngForMap[] = [];
  public parkingsPositions: LatLngForMap[] = [];
  public parkingAreasPositions: GeoJsonForMap[] = [];
  public loading = true;
  public disableCurrentLocationNavigationButton = true;
  public disableParkingLocationNavigationButton = true;
  public showNavigationButton = true;
  public navigationTargetCurrentLocationLat = '0';
  public navigationTargetCurrentLocationLng = '0';
  public navigationTargetParkingLocationLat = '0';
  public navigationTargetParkingLocationLng = '0';
  public displayLocation = false;
  public carsClustering = true;
  public zoomControl = false;
  public zoomLevel = 10;

  get isInServiceMode() {
    return this.$route.name === MapServisRoute;
  }

  get reservationId() {
    return this.$route.params.reservationId;
  }

  get carId() {
    return this.$route.params.carId?.toString() || this.$route.query.carId?.toString();
  }

  async mounted() {
    this.loading = true;

    try {
      if (!this.reservationId && this.carId) {
        await this.showMapInCarMode();
      } else if (this.reservationId === 'all' || this.isInServiceMode) {
        await this.showMapInServiceMode();
      } else {
        await this.showMapInReservationMode();
      }
    } catch (e) {
      this.$toast.error((e as Error).message);
    } finally {
      this.loading = false;
    }
  }

  // this mode is for customer with active reservation
  private async showMapInReservationMode() {
    const reservation = await this.getReservation();
    if (!reservation) return;
    const status = this.getReservationStatus(reservation);
    const car = await this.getCar(reservation.carId);

    if (reservation.active && car) {
      if (status === ReservationStatus.Before) {
        this.parkingsPositions = locationParser.getParkingPositionsFromCars([car]);
        this.parkingAreasPositions = locationParser.getParkingAreasFromCars([car]);
      } else if (status === ReservationStatus.Ongoing) {
        this.carsPositions = locationParser.getCurrentPositionsFromCars([car]);
        this.parkingAreasPositions = locationParser.getParkingAreasFromCars([car]);
        this.parkingsPositions = locationParser.getParkingPositionsFromCars([car]);
        this.setNavigationLinks(car);
      } else if (status === ReservationStatus.After) {
        this.parkingAreasPositions = locationParser.getParkingAreasFromCars([car]);
        this.parkingsPositions = locationParser.getParkingPositionsFromCars([car]);
      }

      this.displayLocation = true;
    }
  }

  // this mode is used in admin to view specific cars
  private async showMapInCarMode() {
    const car = await this.getCar(this.carId);
    if (!car) return;

    this.showNavigationButton = false;
    this.zoomControl = true;
    this.parkingAreasPositions = locationParser.getParkingAreasFromCars([car]);
    this.parkingsPositions = locationParser.getParkingPositionsFromCars([car]);
    this.carsPositions = locationParser.getCurrentPositionsFromCars([car]);
  }

  // the last mode is service mode with all cars
  private async showMapInServiceMode() {
    this.showNavigationButton = false;

    const cars = await carService.allCarsWithCurrentLocation();
    if (!cars.length) return;

    this.carsClustering = false;
    this.zoomControl = true;
    this.carsPositions = locationParser.getCurrentPositionsFromCars(cars, undefined, (car) => ({
      tooltip: `${car.name} | ${car.licensePlate}`,
      permanentTooltip: true
    }));
  }

  private async getCar(carId?: string): Promise<Car | null | undefined> {
    if (!carId) {
      return null;
    }

    try {
      return await carService.getCarById(carId, true);
    } catch (e) {
      this.$toast.error('Pro zobrazení polohy auta se prosím přihlaste.');
    }
  }

  private async getReservation(): Promise<ReservationForMap | undefined> {
    try {
      return await getReservationForMap(this.reservationId);
    } catch (e) {
      this.$toast.error('Pro zobrazení polohy auta se prosím přihlaste.');
    }
  }

  private getReservationStatus(reservation: ReservationForMap): ReservationStatus {
    if (!reservation.start || !reservation.end) {
      throw new Error('Rezervace nemá stanovená začáteční a konečné datum');
    }

    const startDate = moment(reservation.start).utc();
    const endDate = moment(reservation.end).utc();
    const currentDate = moment().utc();

    if (currentDate.isBefore(startDate)) {
      return ReservationStatus.Before;
    } else if (currentDate.isAfter(startDate) && currentDate.isBefore(endDate)) {
      return ReservationStatus.Ongoing;
    } else if (currentDate.isAfter(endDate)) {
      return ReservationStatus.After;
    }

    return ReservationStatus.Ongoing;
  }

  private setNavigationLinks(car: Car) {
    this.navigationTargetCurrentLocationLat = car.currentLocation.lat;
    this.navigationTargetCurrentLocationLng = car.currentLocation.lng;

    this.navigationTargetParkingLocationLat = car.parkingLocation.lat.toString();
    this.navigationTargetParkingLocationLng = car.parkingLocation.lng.toString();

    this.disableCurrentLocationNavigationButton = false;
    this.disableParkingLocationNavigationButton = false;
  }
}
