import { CarCurrentLocation } from '@/models/cars';
import { ParkingWithoutArea } from '@/models/parking';
import { Car } from '@/store/types';
import { LatLngExpression } from 'leaflet';

type IdGenerator<T> = (input: T) => string;
type MetadataGenerator<T> = (input: T) => MapMetadata;
type CarWithLocation = Car | CarCurrentLocation;

export interface MapMetadata {
  tooltip?: string;
  permanentTooltip?: boolean;
}
export type MapElementId = number | string | null;
export type WithId<T> = { element: T; id: MapElementId };
export type LatLngForMap = WithId<LatLngExpression> & { metadata?: MapMetadata };
export type GeoJsonForMap = WithId<GeoJSON.FeatureCollection> & { metadata?: MapMetadata };

export const getCurrentPositionsFromCars = (
  cars: CarWithLocation[],
  getId?: IdGenerator<CarWithLocation>,
  getMetadata?: MetadataGenerator<CarWithLocation>
): LatLngForMap[] => {
  return cars
    .filter((car) => car.currentLocation)
    .map((car) => ({
      element: [parseFloat(car.currentLocation.lat), parseFloat(car.currentLocation.lng)],
      id: getId ? getId(car) : car._id,
      metadata: getMetadata ? getMetadata(car) : undefined
    }));
};

export const getParkingPositionsFromCars = (
  cars: Car[],
  getId?: IdGenerator<Car>,
  getMetadata?: MetadataGenerator<Car>
): LatLngForMap[] => {
  return cars
    .filter((car) => car.parkingLocation)
    .map((car) => ({
      element: [car.parkingLocation.lat, car.parkingLocation.lng],
      id: getId ? getId(car) : car._id,
      metadata: getMetadata ? getMetadata(car) : undefined
    }));
};

export const getParkingAreasFromCars = (
  cars: Car[],
  getId?: IdGenerator<Car>,
  getMetadata?: MetadataGenerator<Car>
): GeoJsonForMap[] => {
  return cars
    .filter((car) => car.parkingArea)
    .map((car) => ({
      element: car.parkingArea,
      id: getId ? getId(car) : car._id,
      metadata: getMetadata ? getMetadata(car) : undefined
    }));
};

export const getParkingPositionsFromParkings = (
  parkings: ParkingWithoutArea[],
  getId?: IdGenerator<ParkingWithoutArea>,
  getMetadata?: MetadataGenerator<ParkingWithoutArea>
): LatLngForMap[] => {
  return parkings
    .filter((parking) => parking.parkingLocation)
    .map((parking) => ({
      element: [parking.parkingLocation.lat, parking.parkingLocation.lng],
      id: getId ? getId(parking) : null,
      metadata: getMetadata ? getMetadata(parking) : undefined
    }));
};
